import React, { useEffect, useState, } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../reducer/store";
import { RiDeleteBinLine } from "react-icons/ri";
import { Dropdown } from 'react-bootstrap';
import Filter from '../../layouts/Filter';
import { readContract } from '@wagmi/core';
import Property_ABI from '../../contract/property.json';
import { config } from '../../slices/config'

function ListProperty() {
  const { items: data, status } = useAppSelector((state) => state.products);
  const { Country } = useAppSelector((state) => state.FiterProperty);
  const [tokensPrice, settokensPrice] = useState(0);
  const [buySell, setbuySell] = useState(0);
  const [datas, setdatas] = useState(null);
  const [checkID, setcheckID] = useState([]);

  const CompleteDetaisl = async (addres) => {
    const result = await readContract(config, {
      abi: Property_ABI,
      address: addres,
      functionName: 'getCompletePropDetails',
    })
    return result;
  }

  useEffect(() => {
    const fetchda = async () => {
      let add = []
      const transformedData = data && data?.map(async (item) => (
        await CompleteDetaisl(item.uid)
          .then((result) => {
            setdatas(result.PropertyDetails)
            if (tokensPrice == 0) {
              settokensPrice(result.PropertyDetails.TokenPrice);
            }
            if (buySell == 0) {
              setbuySell(result.PropertyDetails.BuySellingFee);
            }
            // console.log("Property Details", result.PropertyDetails);
          })
      ));
      add.push(transformedData);

      setcheckID(add);
      console.log(add)
    }
    fetchda();
  }, [])

  // get contract of property
  // useEffect(() => {
  //     const fetchda = async () => {
  //       await CompleteDetaisl(checkID)
  //         .then((result) => {
  //           setdatas(result.PropertyDetails)
  //           if (tokensPrice == 0) {
  //             settokensPrice(result.PropertyDetails.TokenPrice);
  //           }
  //           if (buySell == 0) {
  //             setbuySell(result.PropertyDetails.BuySellingFee);
  //           }
  //           // console.log("Property Details", result.PropertyDetails);
  //         })
  //     }
  //     fetchda();
  // }, [])

  return (
    <>
      <section className="content-inner bg-white">
        <div className="container">
          <div className="row ">
            <div className="col-xl-12 col-lg-4">
              <Filter />
            </div>
            <div className="col-xl-12 col-lg-8">
              <div className="row">
                {status === "success" ? (
                  <>
                    {Country != 0 ? (
                      <>
                        {data &&
                          data
                            ?.filter((key) => key.location == Country)
                            .map((item) => (
                              <div className="col-xl-3 col-md-6" key={item._id}>
                                <div className="card contact_list ">
                                  <div className="card-body text-center">
                                    <div className="user-content">
                                      <div className="user-info">
                                        <div className="user-img">
                                          <Link to={"/propertydetails/" + item._id}>
                                            <img
                                              style={{
                                                width: "13.50rem",
                                                minWidth: "13.50rem",
                                                height: "8.50rem",
                                                borderRadius: "20px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                position: "relative",
                                                zIndex: "0",
                                              }}
                                              src={item.image?.url} alt="" />
                                            <div style={{
                                              position: "absolute",
                                              left: "5%",
                                              right: "50%",
                                              bottom: "70%",
                                              color: "#9467fe",
                                              fontSize: "10px",
                                              textAlign: "center",
                                              width: "auto",
                                              height: "auto",
                                              backgroundColor: "white",
                                              border: "1.5px solid #fff",
                                              borderRadius: "12px"
                                            }}>
                                              <a style={{}}>{item.uid?.substring(0, 11) + "..."}</a>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="user-details">
                                          <div className="ms-0">
                                            <div className="row">
                                              <div>
                                                <span className='fs-8'>{item.propertytype}</span>
                                              </div>
                                              <div>
                                                <span className='fs-8'>{item.propertytype}</span>
                                              </div>
                                            </div>
                                          </div>
                                          <h4 className="user-name">{item.name}</h4>
                                          <p className="mb-0 fs-14 text-success">{item.location}</p>
                                          <div className='col'>
                                            <p className="mb-0 fs-14 text-black">Created</p>
                                            <span className="fs-8">{new Date(item.createdAt).toDateString()}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="contact-icon">
                                      <div className="">
                                        <Link to={"/propertydetails/" + item._id}
                                          type="button" className="btn btn-outline-primary" data-mdb-ripple-init data-mdb-ripple-color="dark">
                                          Explore More
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </>
                    ) : (
                      <>
                        {data && data?.map((item) => (
                          <div className="col-xl-3 col-md-6" key={item._id}>
                            <div className="card contact_list ">
                              <div className="card-body text-center">
                                <div className="user-content">
                                  <div className="user-info">
                                    <div className="user-img">
                                      <Link to={"/propertydetails/" + item._id}>
                                        <img
                                          style={{
                                            width: "13.50rem",
                                            minWidth: "13.50rem",
                                            height: "8.50rem",
                                            borderRadius: "20px",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            position: "relative",
                                            zIndex: "0",
                                          }}
                                          src={item.image?.url} alt="" />
                                        <div style={{
                                          position: "absolute",
                                          left: "5%",
                                          right: "50%",
                                          bottom: "70%",
                                          color: "#9467fe",
                                          fontSize: "13px",
                                          textAlign: "center",
                                          width: "auto",
                                          height: "auto",
                                          backgroundColor: "white",
                                          border: "1.5px solid #fff",
                                          borderRadius: "12px"
                                        }}>
                                          {/* <a style={{}}>{item.uid?.substring(0, 11) + "..."}</a> */}
                                          {item.isStartSelling == false && item.isReStartSelling == false ?
                                            (<a style={{ color: "red" }}>Closed</a>) : (<a style={{ color: "#00a389" }}>Active</a>)}
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="user-details">
                                      <div className="ms-0">
                                        <div className="row" style={{
                                          //  justifyContent: "center", 
                                        }}>
                                          <div style={{
                                            position: "absolute",
                                            color: "#9467fe",
                                            fontSize: "10px",
                                            textAlign: "center",
                                            width: "auto",
                                            height: "auto",
                                            backgroundColor: "white",
                                            border: "1.5px solid #9467fe",
                                            borderRadius: "12px",
                                            marginLeft: "40px",

                                          }}>
                                            {item.bathroom} Baths
                                          </div>
                                          <div style={{
                                            position: "absolute",
                                            color: "#9467fe",
                                            fontSize: "10px",
                                            textAlign: "center",
                                            width: "auto",
                                            height: "auto",
                                            backgroundColor: "white",
                                            border: "1.5px solid #9467fe",
                                            borderRadius: "12px",
                                            marginLeft: "130px",
                                          }}>
                                            {item.bedroom} Beds
                                          </div>
                                        </div>
                                      </div>
                                      <h4 className="user-name">{item.name}</h4>
                                      <a>{item.propaddress} {item.location}</a>
                                      <table style={{
                                        width: "100%",
                                        borderBottom: "2px solid black",
                                        lineHeight: "19.5px",
                                        marginTop: "17px"
                                      }}>
                                        <tr style={{ backgroundColor: "white" }}>
                                          <td
                                            style={{
                                              verticalAlign: "baseline",
                                              textAlign: "left",
                                            }}
                                          ><h6
                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                              Token Price</h6>
                                          </td>
                                          <td style={{
                                            verticalAlign: "baseline",
                                            textAlign: "right",
                                            fontFamily: "Arial",
                                          }}>
                                            <h4 style={{ marginBottom: "0px", fontFamily: "Arial", }}
                                            >${(item.tokenPrice / 1e8).toFixed(2)}
                                            </h4>
                                          </td>
                                        </tr>
                                      </table>
                                      <table style={{
                                        width: "100%",
                                        borderBottom: "2px solid black",
                                        lineHeight: "19.5px",
                                        marginTop: "17px"
                                      }}>
                                        <tr style={{ backgroundColor: "white" }}>
                                          <td
                                            style={{
                                              verticalAlign: "baseline",
                                              textAlign: "left",
                                              fontFamily: "Arial",
                                              fontSize: "25px",
                                              fontWeight: "55px"
                                            }}
                                          ><h6
                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                              Market Cap
                                            </h6>
                                          </td>
                                          <td style={{
                                            verticalAlign: "baseline",
                                            textAlign: "right",
                                            fontFamily: "Arial",
                                            fontSize: "22px"
                                          }}>
                                            <h4 style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                              ${(((item.totalSupply - item.tokenBlance) / 1e18)*(item.tokenPrice / 1e8)).toFixed(1)}
                                            </h4>
                                          </td>
                                        </tr>
                                      </table>
                                      <table style={{
                                        width: "100%",
                                        lineHeight: "19.5px",
                                        marginTop: "17px"
                                      }}>
                                        <tr style={{ backgroundColor: "white" }}>
                                          <td
                                            style={{
                                              verticalAlign: "baseline",
                                              textAlign: "left",
                                              fontFamily: "Arial",
                                              // fontSize: "25px",
                                              fontWeight: "55px"
                                            }}
                                          ><p
                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                              Available Token
                                            </p>
                                          </td>
                                          <td style={{
                                            verticalAlign: "baseline",
                                            textAlign: "right",
                                            fontFamily: "Arial",
                                          }}>
                                            <p style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                              {(item.tokenBlance / 1e18).toFixed(2)}
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                      <table style={{
                                        width: "100%",
                                        lineHeight: "19.5px",
                                        marginTop: "17px"
                                      }}>
                                        <tr style={{ backgroundColor: "white" }}>
                                          <td
                                            style={{
                                              verticalAlign: "baseline",
                                              textAlign: "left",
                                              fontFamily: "Arial",
                                              // fontSize: "25px",
                                              fontWeight: "55px"
                                            }}
                                          ><p
                                            style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                              Total Supply
                                            </p>
                                          </td>
                                          <td style={{
                                            verticalAlign: "baseline",
                                            textAlign: "right",
                                            fontFamily: "Arial",
                                          }}>
                                            <p style={{ marginBottom: "0px", fontFamily: "Arial", }}>
                                              {(item.totalSupply / 1e18).toFixed(2)}
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                     
                                      {/* <p className="mb-0 fs-14 text-success">{item.location}</p> */}
                                      {/* <div className='col'>
                                        <p className="mb-0 fs-14 text-black">Created</p>
                                        <span className="fs-8">{new Date(item.createdAt).toDateString()}</span>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="contact-icon">
                                  <div className="">
                                    <Link to={"/propertydetails/" + item._id}
                                      type="button" className="btn btn-outline-primary" data-mdb-ripple-init data-mdb-ripple-color="dark">
                                      View Property
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      </>
                    )}
                  </>
                ) : status === "pending" ? (
                  <p>Loading...</p>
                ) : (
                  <p>Unexpected error occurred...</p>
                )}

                <div className="col-xl-12 col-lg-12 m-b30 m-t30 m-lg-t10">
                  <nav aria-label="Blog Pagination">
                    <ul className="pagination style-1 text-center">
                      <li className="page-item"><Link to={"#"} className="page-link prev"><i className="fas fa-chevron-left"></i></Link></li>
                      <li className="page-item"><Link to={"#"} className="page-link active">1</Link></li>
                      <li className="page-item"><Link to={"#"} className="page-link">2</Link></li>
                      <li className="page-item"><Link to={"#"} className="page-link">3</Link></li>
                      <li className="page-item"><Link to={"#"} className="page-link next"><i className="fas fa-chevron-right"></i></Link></li>
                    </ul>
                  </nav>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default ListProperty;
