// Importing necessary libraries and components
import React from 'react';
import Route from './pages/route'; // Main routing component
import './index.css'; // Global CSS styles
import "./assets/css/style.css"; // Additional CSS styles
import "./pages/style"; // Page-specific styles
import ChatwootWidget from './components/ChatwootWidget'; // Chat widget component
import { Fragment } from 'react'; // Fragment component from React

// Main App component
function App() {
  return (
    <div className="App">
      <Fragment>
        {/* Chat widget for customer support */}
        <ChatwootWidget />
        {/* Main routing component to handle different pages */}
        <Route />
      </Fragment>
    </div>
  );
}

// Exporting the App component as the default export
export default App;
