import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../../reducer/store";
import { FILTERPROP } from "../../../slices/filter";

const CountryDropdown = () => {
  const [location, setlocation] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(FILTERPROP(location));
  });

  return (
    <StyledForm>
      <select className="tagcloud"
        // className="bg-transparent"
        style={{
          // borderRadius: "12px",
          color: "#371178",
          fontStyle: "normal",
          fontWeight: "500",
        }}
        onChange={(e) => setlocation(e.target.value)}
      >
        <option value="">All Markets</option>
        <option value="El Salvador">El Salvador</option>
        <option value="Alabama">Alabama</option>
        <option value="Alaska">Alaska</option>
        <option value="Arizona">Arizona</option>
        <option value="Arkansas">Arkansas</option>
        <option value="California">California</option>
        <option value="Colorado">Colorado</option>
        <option value="Connecticut">Connecticut</option>
        <option value="Delaware">Delaware</option>
        <option value="Florida">Florida</option>
        <option value="Georgia">Georgia</option>
        <option value="Hawaii">Hawaii</option>
        <option value="Idaho">Idaho</option>
        <option value="Illinois">Illinois</option>
        <option value="Indiana">Indiana</option>
        <option value="Iowa">Iowa</option>
        <option value="Kansas">Kansas</option>
        <option value="Kentucky">Kentucky</option>
        <option value="Louisiana">Louisiana</option>
        <option value="Maine">Maine</option>
        <option value="Maryland">Maryland</option>
        <option value="Massachusetts">Massachusetts</option>
        <option value="Michigan">Michigan</option>
        <option value="Minnesota">Minnesota</option>
        <option value="Mississippi">Mississippi</option>
        <option value="Missouri">Missouri</option>
        <option value="Montana">Montana</option>
        <option value="Nebraska">Nebraska</option>
        <option value="Nevada">Nevada</option>
        <option value="New Hampshire">New Hampshire</option>
        <option value="New Jersey">New Jersey</option>
        <option value="New Mexico">New Mexico</option>
        <option value="New York">New York</option>
        <option value="North Carolina">North Carolina</option>
        <option value="North Dakota">North Dakota</option>
        <option value="Ohio">Ohio</option>
        <option value="Oklahoma">Oklahoma</option>
        <option value="Oregon">Oregon</option>
        <option value="Pennsylvania">Pennsylvania</option>
        <option value="Rhode Island">Rhode Island</option>
        <option value="South Carolina">South Carolina</option>
        <option value="South Dakota">South Dakota</option>
        <option value="Tennessee">Tennessee</option>
        <option value="Texas">Texas</option>
        <option value="Utah">Utah</option>
        <option value="Vermont">Vermont</option>
        <option value="Virginia">Virginia</option>
        <option value="Washington">Washington</option>
        <option value="West Virginia">West Virginia</option>
        <option value="Wisconsin">Wisconsin</option>
        <option value="Wyoming">Wyoming</option>
        <option value="District of Columbia">District of Columbia</option>
        <option value="American Samoa">American Samoa</option>
        <option value="Guam">Guam</option>
        <option value="Northern Mariana Islands">
          Northern Mariana Islands
        </option>
        <option value="Puerto Rico">Puerto Rico</option>
        <option value="California"> U.S. Virgin Islands</option>
      </select>
    </StyledForm>
  );
};

export default CountryDropdown;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 170px;

  select,
  input {
    padding: 7px;
    min-height: 30px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin: 0.3rem 0;

    &:focus {
      border: 2px solid rgb(0, 208, 255);
    }
  }

  select {
    color: rgb(95, 95, 95);
  }
`;
