// export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const USDT_ADDRESS = "0xdF137d51199ecCf4f667fcF9d8C1C7809C47f88e";
export const USDC_ADDRESS = "0xB5f2449917e29d08fB4cA4Ebe6fe7EF3Ed0DCD6e";
export const projectId = '6467c18267b081ccdb07148fff803e6f';
export const USDCaddr = "0x0153002d20B96532C639313c2d54c3dA09109309";
export const USDTaddr = "0x80EDee6f667eCc9f63a0a6f55578F870651f06A4";
const WhiteList_ADDRESS = "0x33Be53b4B011249a363c8b79e84E5be304304D36";
export const aggregatorV3InterfaceABI = [
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "description",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
    name: "getRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
]
